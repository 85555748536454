import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Octicon, { gitBranch } from 'octicons-react';
import ProjectPageContent from './ProjectPageContent'
import './ProjectPage.css'



export class ProjectPage extends Component {


  // State for the app - Assume we would fetch this from backend and such
  state = {
    expanded: false,
  }

  toggleExpand = () => {
    this.setState((state) => {
      return {
        expanded: !state.expanded,
      }
    });
  }

  render() {
    const { title, summary, id, date, active, repository } = this.props.project;
    
    const expanded = this.props.expanded;

    return (
      <div className={this.props.style} onClick={this.props.selectProject.bind(this, this.props.projectIndex)}>
        <div className="ProjectPageHeader">
          { date ? <div className={active ? "ProjectDateMarkerActive" : "ProjectDateMarkerInactive"}>{date}</div> : null }
          {repository ? <div className="ProjectRepoLink"><a href={repository}><Octicon icon={gitBranch} scale={2}/></a></div> : null }
          <h2 align="center">{title}</h2>
          <h3 align="center">{summary}</h3>
          <img align="center" src={expanded ? 'arrow_up.png' : 'arrow_down.png'} alt='Expand' className='ExpandArrow'/>
          <hr />
        </div>
          <CSSTransition
            in={expanded}
            classNames="ProjectContent"
            timeout={300}
            unmountOnExit
            >
            
            <div className="ProjectContent">
              <ProjectPageContent projectId={id}/>
            </div>

          </CSSTransition>
        
      </div>
    )
  }
}

// Define prop types for this class
ProjectPage.propTypes = {
  project: PropTypes.object.isRequired,
  projectIndex: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  style: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
}

export default ProjectPage
